<template>
  <div>
    <!-- MOBILE -->
    <div class="mb-5 pb-10" style="height:100%">
      <div v-if="reload" class="row pl-3 pr-3 d-flex justify-space-between pt-3">
        <v-card
          width="100%"
          height="270px"
          class="round mb-3"
          elevation="0"
          outlined
          v-for="n in 3"
          :key="n+'A'"
        >
        <v-skeleton-loader
          type="card"
        ></v-skeleton-loader>
        </v-card>
      </div>
      <div v-else>
        <v-expansion-panels  class="round" v-model="openExpand">
          <v-expansion-panel class="fourth">
            <v-expansion-panel-header class="body-1">
              <b>FAQ untuk Brand</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panels  class="round">
                <v-expansion-panel>
                  <v-expansion-panel-header class="body-1">
                    <b>Apa itu VDC ?</b>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>VDC adalah influencer & KOL management platform. VDC bukan hanya sekadar penghubung antara Brand dengan Influencer saja, tapi VDC juga turut serta membantu Brand untuk membangun bisnisnya dalam berbagai aspek mulai dari brand awareness, promosi produk, hingga strategi untuk dapat meningkatkan penjualan. Semua hal tersebut tersedia di VDC.</p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header class="body-1">
                    <b>Apa keuntungan menggunakan VDC ?</b>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="active--class">
                    <p>Untuk memenuhi kebutuhan brand Anda, kami menyediakan talent yang tersebar di seluruh Indonesia. Dan tentunya talent yang miliki sudah diseleksi dengan ketat agar sesuai dengan kebutuhan brand Anda.</p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header class="body-1">
                    <b>Apa perbedaan VDC dengan management influencer lainnya ?</b>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="active--class">
                    <p>Kami merancang platform VDC dengan sebaik mungkin, agar klien dengan mudah mencari influencer/KOL sesuai yang dibutuhkan dalam 1 platform saja karena brand dapat menjalankan campaign kapanpun saat dibutuhkan. Sehingga campaign dapat lebih efektif dan efisien.</p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header class="body-1">
                    <b>Bagaimana cara membuat campaign di VDC ?</b>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="active--class">
                    <p>Kamu hanya perlu klik menu <b>Campaign</b> atau daftar di menu <b>Register</b>, selanjutnya ikuti panduan dan langkah-langkah yang diberikan.</p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header class="body-1">
                    <b>Sistem pembayaran seperti apa yang berlaku di VDC ?</b>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="active--class">
                    <p>Saat ini kami menerima pembayaran melalui Bank Transfer dan E-Wallet (Dana, GoPay, Ovo).</p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header class="body-1">
                    <b>Apakah saya bisa membatalkan campaign yang telah dibuat ?</b>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="active--class">
                    <p>Ya, kamu bisa membatalkan campaign jika <b>Quotation Approval</b> belum di tandatangani oleh pihak brand.</p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="fourth">
            <v-expansion-panel-header class="body-1">
              <b>FAQ untuk Influencers</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panels  class="round">
                <v-expansion-panel>
                  <v-expansion-panel-header class="body-1">
                    <b>Apa itu VDC ?</b>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>VDC Management merupakan platform Influencer & KOL Management dari Vice Digital Creative  yang akan menghubungkan kamu dengan berbagai brand dari seluruh Indonesia.</p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header class="body-1">
                    <b>Apa keuntungan menggunakan VDC ?</b>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="active--class">
                    <p>Keuntungan untuk influencer adalah kamu dapat berkesempatan untuk bisa kerjasama dengan banyak brand dari seluruh Indonesia, mulai dari bisnis rumahan berskala kecil hingga bisnis berskala besar, dan mendapatkan penghasilan dari social media kamu sendiri.</p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header class="body-1">
                    <b>Apakah saya dikenakan biaya pendaftaran di VDC ?</b>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="active--class">
                    <p>Tidak, kamu tidak akan dipungut biaya apapun saat bergabung dengan VDC.</p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header class="body-1">
                    <b>Berapakah minimal followers yang dibutuhkan jika ingin bergabung ?</b>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="active--class">
                    <p>Untuk menjadi bagian dari VDC, kamu harus mempunyai minimal 1.000 followers di Instagram atau Tiktok. Selalu ikut trend update dan akunmu tidak diprivate ya!</p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header class="body-1">
                    <b>Apakah saya bisa menolak campaign/promosi yang tidak cocok ?</b>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="active--class">
                    <p>Bisa, kamu selalu memiliki pilihan untuk menyetujui atau menolak setiap iklan / campaign yang ditawarkan ke kamu.</p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header class="body-1">
                    <b>Apakah ada syarat lain jika saya sudah bergabung dengan VDC ?</b>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="active--class">
                    <p>Kamu harus bersedia untuk mencantumkan link VDC di profil sosial media kamu.</p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <p class="text-center mt-5">Jangan tunggu besok, langsung chat admin VDC sekarang ya!</p>
        <div class="d-flex justify-center">
          <v-btn @click="goToWa()" color="primary" rounded class="text-capitalize"><i class="text-18 fab fa-whatsapp mr-2"></i>Chat Admin</v-btn>
        </div>
      </div>
    </div>

    <!-- DESKTOP -->

  </div>
</template>

<script>
import axios from 'axios'
export default {
  props:['data'],
  setup() {
    return {
      isLoading:true,
      reload:true,
      isMobileWidth:0,
      openExpand:''
    }
  },
  mounted(){
    if(this.isMobileWidth === 0){
      if (localStorage.getItem('reloaded')) {
          localStorage.removeItem('reloaded');
          this.reload = false
      } else {
          localStorage.setItem('reloaded', '1');
          location.reload();
      }
      window.scrollTo(0,0);
    }

    window.scrollTo(0,0);
    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })

    if(this.isMobileWidth === 0){
      this.openExpand = 0
    } else {
      this.openExpand = ''
    }
  },
  methods:{
    decryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg=';
      let decryptData = this.$CryptoJS.AES.decrypt(val, secretKey).toString(this.CryptoJS.enc.Utf8);
      return decryptData;
    },
    goToWa(){
      window.open('https://wa.me/6285691701929?text=Halo Admin, Boleh dijelaskan lebih detail terkait service yang ada di VDC ini? \n Terima kasih', '_blank');
    },
    isMobile() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
  },
  filters:{
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    nFormatter(num) {
      if (num >= 1000000000) {
          return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
      }
      if (num >= 1000000) {
          return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
      }
      if (num >= 1000) {
          return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
      }
      return num;
    }
  }
}
</script>
